import React from 'react'
import createReactClass from 'create-react-class'
import ReactDOM from 'react-dom'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'
import DatePicker from 'material-ui/DatePicker'

import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

const SelectGroup = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { value = this.defaultValue } = this.props
        return {
            values: this.generateValues(value),
            containerwidth: 0,
            isMounted: false
        }
    },
    resizeListenerCatchOn: function() {
        this.updateDimensions()
        setTimeout(this.updateDimensions, 500)
    },
    updateDimensions: function(go) {
        const { containerwidth, isMounted } = this.state
        const newwidth = this.getContainerWidth()
        if (newwidth !== containerwidth && isMounted || go === true) {
            this.state.containerwidth = newwidth
            this.setState({
                containerwidth: newwidth,
                isMounted: true
            })
        }
    },
    componentDidMount: function() {
        window.addEventListener('resize', this.updateDimensions)
        document.addEventListener('mouseup', this.resizeListenerCatchOn)
        this.updateDimensions(true)
    },
    componentWillUnmount: function() {
        window.removeEventListener('resize', this.updateDimensions)
        document.removeEventListener('mouseup', this.resizeListenerCatchOn)
        this.state.isMounted = false
        this.setState({
            isMounted: false
        })
    },
    generateValues: function(value) {
        const { column = this.defaultColumn } = this.props
        const { row = this.defaultRow } = this.props
        const v = []
        row.map(function(r, i) {
            v.push([])
            column.map(function(c, ii) {
                if (value && value[i] && value[i][ii]) {
                    v[i][ii] = value[i][ii]
                } else {
                    v[i][ii] = null
                }
            })
        })
        return v
    },
    defaultValue: [[]],
    defaultRow: [{ name: 'Row 1' }],
    defaultColumn: [{ name: 'Column 1', data: [{ name: '', value: null }, { name: 'Value 1', value: 1 }] }],
    setValues: function(a = {}) {
        this.setState({
            values: a.values
        })
    },
    getValues: function() {
        return this.state.values
    },
    handleChange: function({ event, value, currentrow, currentcolumn }) {
        const { onChange } = this.props
        const { values = this.defaultValue } = this.state
        const setState = this.setValues
        values[currentrow][currentcolumn] = value
        if (onChange) {
            onChange(event, values, setState)
        } else {
            this.setState({
                values: values
            })
        }
    },
    getColumnNumberToView: function() {
        const { column = this.defaultColumn, responsiveColumns } = this.props
        const state = this.state
        const { containerwidth } = state
        let cn = column.length
        if (responsiveColumns) {

            Object.keys(responsiveColumns).map(function(w) {
                if (w && !isNaN(Number(w)) && responsiveColumns[w] && !isNaN(Number(responsiveColumns[w]))) {
                    const wn = Number(w)
                    if (wn + 1 > containerwidth && responsiveColumns[w] < cn) {
                        cn = responsiveColumns[w]
                    }
                }
            })
        }
        return cn
    },
    ReturnSelect: function({
                               noeditjustshow,
                               currentcolumn,
                               currentrow,
                               maxWidth,
                               width,
                               style,
                               value,
                               selectattr,
                               data,
                               name,
                               handleChange
                           }) {

        if (noeditjustshow) {

            const stlyeattr = {
                underlineDisabledStyle: {
                    borderBottom: 'solid',
                    borderBottomWidth: '1px',
                    borderBottomColor: 'transparent'
                },
                iconStyle: {
                    visibility: 'hidden'
                },
                labelStyle: {
                    color: 'rgba(0, 0, 0, 0.87)'
                }
            }

            return (
                <div key={currentcolumn} style={{ maxWidth: maxWidth, width: width }} className={style.cursorallowed}>
                    <SelectField
                        disabled={true}
                        floatingLabelText={name}
                        style={{ width: '100%', cursor: 'default', fontSize: '14px' }}
                        selectedMenuItemStyle={{ color: '#ffffff', backgroundColor: '#3f454a' }}
                        value={value}
                        {...selectattr}
                        {...stlyeattr}
                    >
                        {data.map(function(d, key) {
                            return (d.value === value) ?
                                <MenuItem key={key} value={d.value} primaryText={d.name} /> : null
                        })}
                    </SelectField>
                </div>
            )

        } else {

            return (
                <div key={currentcolumn} style={{ maxWidth: maxWidth, width: width }}>
                    <SelectField
                        disabled={(!(data && data.length))}
                        floatingLabelText={name}
                        style={{ width: '100%', fontSize: '14px' }}
                        selectedMenuItemStyle={{ color: '#ffffff', backgroundColor: '#3f454a' }}
                        value={value}
                        onChange={function(event, index, value) {
                            handleChange({ event, index, value, currentrow, currentcolumn })
                        }}
                        {...selectattr}
                    >
                        {data.map(function(d, key) {
                            return <MenuItem key={key} value={d.value} primaryText={d.name} />
                        })}
                    </SelectField>
                </div>
            )

        }
    },
    ReturnDatePicker: function({
                                   noeditjustshow,
                                   currentcolumn,
                                   currentrow,
                                   maxWidth,
                                   width,
                                   style,
                                   value,
                                   selectattr,
                                   name,
                                   handleChange
                               }) {

        if (noeditjustshow) {

            const stlyeattr = {
                underlineDisabledStyle: {
                    borderBottom: 'solid',
                    borderBottomWidth: '1px',
                    borderBottomColor: 'transparent'
                },
                textFieldStyle: {
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: '14px',
                    width: '100%'
                },
                style: {
                    width: '100%',
                    color: 'rgba(0, 0, 0, 0.87)'
                }
            }

            return (
                <div className={style.datepickerdisabled} key={currentcolumn}
                     style={{ maxWidth: maxWidth, width: width }}>
                    <DatePicker
                        disabled={true}
                        floatingLabelText={name}
                        value={value}
                        {...selectattr}
                        {...stlyeattr}
                    />
                </div>
            )

        } else {

            const stlyeattr = {
                textFieldStyle: {
                    fontSize: '14px',
                    width: '100%'
                },
                style: {
                    width: '100%'
                }
            }

            return (
                <div key={currentcolumn} style={{ maxWidth: maxWidth, width: width }}>
                    <DatePicker
                        floatingLabelText={name}
                        style={{ width: '100%' }}
                        value={value}
                        onChange={function(event, value) {
                            handleChange({ event, value, currentrow, currentcolumn })
                        }}
                        {...selectattr}
                        {...stlyeattr}
                    />
                </div>
            )

        }
    },
    getSelect: function(props) {

        const state = this.state
        const { selectdata, currentrow, currentcolumn } = props
        const { containerwidth, values = this.defaultValue } = state
        const { style, noeditjustshow } = this.props

        const cnview = this.getColumnNumberToView()
        const maxWidth = containerwidth / cnview + 'px'
        const width = 100 / cnview + '%'

        const rdata = (typeof selectdata == 'function') ? selectdata({ state, currentrow, currentcolumn }) : selectdata
        const { name, data, selectattr, type } = rdata
        const value = (values[currentrow] && values[currentrow][currentcolumn]) ? values[currentrow][currentcolumn] : null
        const handleChange = this.handleChange

        const ReturnSelect = this.ReturnSelect
        const ReturnDatePicker = this.ReturnDatePicker

        if (type === 'datepicker') {
            return ReturnDatePicker({
                noeditjustshow,
                currentcolumn,
                currentrow,
                maxWidth,
                width,
                style,
                value,
                selectattr,
                data,
                name,
                handleChange
            })
        } else {
            return ReturnSelect({
                noeditjustshow,
                currentcolumn,
                currentrow,
                maxWidth,
                width,
                style,
                value,
                selectattr,
                data,
                name,
                handleChange
            })
        }

    },
    getRow: function(currentrow) {
        const { column = this.defaultColumn } = this.props
        const getSelect = this.getSelect
        const colums = []
        column.map(function(selectdata, currentcolumn) {
            colums.push(getSelect({ selectdata, currentrow, currentcolumn }))
        })
        return colums
    },
    getRows: function() {
        const { style, row = this.defaultRow } = this.props
        const getRow = this.getRow
        const rows = []
        row.map(function(r, k) {
            rows.push(
                <div key={k} className={style.row}>
                    {getRow(k)}
                </div>
            )
        })
        return rows
    },
    getElement: function(e) {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    },
    getContainerWidth: function() {
        const container = this.getElement(this.refElements['container'])
        return (container && container.offsetWidth) ? container.offsetWidth : this.state.containerwidth
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    title: function() {
        const { title, style } = this.props
        if (title) {
            return (
                <div className={style.subheader + ' ' + style.title}>{title}</div>
            )
        }
        return null
    },
    error: function() {
        const { error, errorMessage, style } = this.props
        if (error && errorMessage) {
            return (
                <div className={style.subheader + ' ' + style.error}>{errorMessage}</div>
            )
        }
        return null
    },
    render: function() {
        const { style, error, errorMessage, title, containerStyle = {} } = this.props
        const getRows = this.getRows
        const setRef = this.setRef

        const geterror = this.error
        const gettitle = this.title

        return (
            <div style={containerStyle}>
                <div ref={function(e) {
                    setRef('container', e)
                }} className={style.rows}>
                    {(title) ?
                        <div className={style.subheadercontainer}>
                            {gettitle()}
                        </div>
                        : null
                    }
                    {getRows()}
                    {(error && errorMessage) ?
                        <div className={style.subheadercontainer}>
                            {geterror()}
                        </div>
                        : null
                    }
                </div>
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <SelectGroup {...this.props} ref={(setRef) ? function(e) {
                setRef('selectgroup', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})

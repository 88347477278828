import React from 'react'
import createReactClass from 'create-react-class'
import Formsy from 'formsy-react'
import SelectGroup from './index'

export default createReactClass({
    mixins: [Formsy.Mixin],
    componentWillMount: function() {

    },
    recursiveChanged: function(a, s) {
        const recursiveChanged = this.recursiveChanged
        let changed = false
        if (typeof a == 'object') {
            const keys = (a && a.length) ? Object.keys({ ...a }) : Object.keys(a)
            keys.map(function(k) {
                if (!changed) {
                    if (a[k] && typeof a[k] == 'object') {
                        if (!s[k]) {
                            changed = true
                        } else {
                            changed = recursiveChanged(a[k], s[k])
                        }
                    } else {
                        if (s[k] !== a[k]) {
                            changed = true
                        }
                    }
                }
            })
        } else {
            if (a !== s) changed = true
        }
        return changed
    },
    setState: function(a = {}, callback) {
        const s = (this.state) ? this.state : {}
        const newstate = { ...s, ...a }
        let changed = this.recursiveChanged(newstate, s)
        if (changed) {
            this.setStateOriginal(a, callback)
        } else {
            this.state = newstate
            if (callback) callback()
        }
    },
    setStateOriginal: function(partialState, callback) {
        this.updater.enqueueSetState(this, partialState)
        if (callback) {
            this.updater.enqueueCallback(this, callback, 'setState')
        }
    },
    changeValue(event, data, compSetState) {
        const { onChange } = this.props
        const setValue = this.setValue
        const state = this.state
        const setState = this.setState
        if (onChange) {
            onChange({ event, data, compSetState, setValue, setState, state })
        } else {
            this.setValue(data)
        }
    },
    getValues: function() {
        const sg = this.refElements['selectgroup']
        let values
        if (sg && sg.getValues) {
            values = sg.getValues()
        } else {
            values = this.getValue()
        }
        return values
    },
    setValues: function() {
        const nv = this.getValues()
        this.setValue(nv)
        return { state: this.state, values: nv }
    },
    refElements: {},
    setRef: function(a, e) {
        const { setRef } = this.props
        this.refElements[a] = e
        if (setRef) setRef(a, e)
    },
    render() {

        const required = this.showRequired()
        const error = this.showError()
        const errorMessage = this.getErrorMessage()
        const setRef = this.setRef
        const value = this.getValue()

        return (
            <SelectGroup {...this.props}
                         formsy={true}
                         value={this.getValue()}
                         onChange={this.changeValue}
                         error={error}
                         errorMessage={(value && value.length) ? errorMessage : null}
                         required={required}
                         setRef={setRef}
            />
        )
    }
})
